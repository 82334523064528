export const lightTheme = {
    application: {
        main: '#108c58',
        light: '#b7ddcd',
        dark: '#289769',
        gradient: 'linear-gradient(180deg, #108c58 0%, #0a5234 100%)',
        selected: '#e7F3ee',
        contrastText: '#fff'
    },
    header: {
        main: '#36393f',
        contrastText: '#fff'
    },
    logo: {
        light: '#cfe8de',
        middle: '#40a379',
        main: '#108c58',
        dark: '#0e6b41',
        background: '#fff',
        constrastText: '#231815'
    },
    notification: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#108c58'
    },
    search: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#108c58'
    },
    grid: {
        header: '#e7F3ee',
        blankLine: '#ffffff',
        contrastLine: '#f7fffc',
        hoverLine: '#666',
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#108c58'
    },
    new: {
        light: '#108c58',
        main: '#108c58',
        dark: '#108c58',
        contrastText: '#fbfbfb'
    },
    edit: {
        light: '#108c58',
        main: '#108c58',
        dark: '#108c58',
        contrastText: '#fbfbfb'
    },
    delete: {
        light: '#333333',
        main: '#333333',
        dark: '#333333',
        contrastText: '#fbfbfb'
    },
    background: {
        light: '#f5f5f5',
        main: '#f5f5f5',
        dark: '#f5f5f5',
        contrastText: '#000'
    },
    required: {
        light: '#ffe3e2',
        main: '#ffe3e2',
        dark: '#ffe3e2',
        contrastText: '#a40925'
    },
    primary: {
        light: '#cbf4cc',
        main: '#108c58',
        dark: '#289769',
        contrastText: '#fff'
    },
    secondary: {
        light: '#cbf4cc',
        main: '#108c58',
        dark: '#289769',
        contrastText: '#fff'
    },
    success: {
        light: '#baedea',
        main: '#289769',
        dark: '#baedea',
        contrastText: '#baedea'
    },
    error: {
        light: '#ffe3e2',
        main: '#a40925',
        dark: '#ffe3e2',
        contrastText: '#ffe3e2'
    },
    warning: {
        light: '#fff5ca',
        main: '#d98201',
        dark: '#fff5ca',
        contrastText: '#fff5ca'
    },
    info: {
        light: '#e2e5ec',
        main: '#9ba0ac',
        dark: '#e2e5ec',
        contrastText: '#e2e5ec'
    },
    generic: {
        main: "#333",
        contrastText: "#fff"
    },
    common: {
        black: '#333',
        white: '#fff',
        red: '#a40925',
        gray: '#9ba0ac',
        orange: '#ff6c2e'
    },
    document: {
        light: '#b7ddcd',
        main: '#108c58',
        dark: '#289769'
    },
    dashboard: {
        amber: {
            main: '#d98201',
            contrastText: '#fff5ca'
        },
        blue: {
            main: '#1c32a8',
            contrastText: '#dae4ff'
        },
        blueGrey: {
            main: '#fff',
            contrastText: '#000'
        },
        green: {
            main: '#289769',
            contrastText: '#cbf4cc'
        },
        grey: {
            main: '#fff',
            contrastText: '#000'
        },
        purple: {
            main: '#fff',
            contrastText: '#000'
        },
        red: {
            main: '#a40925',
            contrastText: '#ffe3e2'
        },
        yellow: {
            main: '#fff',
            contrastText: '#000'
        },
    }
}