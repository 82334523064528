export const darkTheme = {
    application: {
        main: '#108c58',
        light: '#289769',
        dark: '#b7ddcd',
        gradient: 'linear-gradient(180deg, #108c58 0%, #0a5234 100%)',
        selected: '#289769',
        contrastText: '#fff'
    },
    header: {
        main: '#36393f',
        contrastText: '#fff'
    },
    logo: {
        light: '#cfe8de',
        middle: '#40a379',
        main: '#108c58',
        dark: '#0e6b41',
        background: '#231815',
        constrastText: '#fff'
    },
    notification: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#108c58'
    },
    search: {
        light: '#333',
        main: '#333',
        dark: '#333',
        contrastText: '#b7ddcd'
    },
    grid: {
        header: '#333',
        blankLine: '#111',
        contrastLine: '#222',
        hoverLine: '#666',
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#108c58'
    },
    new: {
        light: '#108c58',
        main: '#108c58',
        dark: '#108c58',
        contrastText: '#fbfbfb'
    },
    edit: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#108c58'
    },
    delete: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#333333'
    },
    background: {
        light: '#000',
        main: '#000',
        dark: '#000',
        contrastText: '#F5F5F5'
    },
    required: {
        light: '#ffe3e2',
        main: '#ffe3e2',
        dark: '#ffe3e2',
        contrastText: '#a40925'
    },
    primary: {
        light: '#cbf4cc',
        main: '#108c58',
        dark: '#289769',
        contrastText: '#fff'
    },
    secondary: {
        light: '#cbf4cc',
        main: '#108c58',
        dark: '#289769',
        contrastText: '#fff'
    },
    success: {
        light: '#CBF4CC',
        main: '#43A047',
        dark: '#076F0C',
        contrastText: '#076F0C'
    },
    error: {
        light: '#FFE3E2',
        main: '#E53936',
        dark: '#A40925',
        contrastText: '#A40925'
    },
    warning: {
        light: '#FFF5CA',
        main: '#FDD835',
        dark: '#D98201',
        contrastText: '#D98201'
    },
    info: {
        light: '#DAE4FF',
        main: '#1E88E5',
        dark: '#1C32A8',
        contrastText: '#1C32A8'
    },
    generic: {
        main: '#333',
        contrastText: '#fff'
    },
    common: {
        black: '#333',
        white: '#fff',
        red: '#a40925',
        gray: '#9ba0ac',
        orange: '#ff6c2e'
    },
    document: {
        light: '#b7ddcd',
        main: '#108c58',
        dark: '#289769'
    },
    dashboard: {
        amber: {
            main: '#D98201',
            contrastText: '#FFF5CA'
        },
        blue: {
            main: '#1C32A8',
            contrastText: '#DAE4FF'
        },
        blueGrey: {
            main: '#fff',
            contrastText: '#000'
        },
        green: {
            main: '#076F0C',
            contrastText: '#CBF4CC'
        },
        grey: {
            main: '#fff',
            contrastText: '#000'
        },
        purple: {
            main: '#fff',
            contrastText: '#000'
        },
        red: {
            main: '#A40925',
            contrastText: '#FFE3E2'
        },
        yellow: {
            main: '#fff',
            contrastText: '#000'
        },
    },
}