import app_en from './locales/en/app.json';
import common_en from './locales/en/common.json';
import locale_en from './locales/en/locale.json';
import breadcrumb_en from './locales/en/breadcrumb.json';
import menu_en from './locales/en/menu.json';
import message_en from './locales/en/message.json';
import notification_en from './locales/en/notification.json';
import dataTransformation_en from './locales/en/dataTransformation.json';
import auth_en from './locales/en/auth.json';
import approval_en from './locales/en/approval.json';
import company_en from './locales/en/company.json';
import subscription_en from './locales/en/subscription.json';
import user_en from './locales/en/user.json';
import department_en from './locales/en/department.json';
import partner_en from './locales/en/partner.json';
import item_en from './locales/en/item.json';
import itemClassification_en from './locales/en/itemClassification.json';
import campaign_en from './locales/en/campaign.json';
import pricelist_en from './locales/en/pricelist.json';
import catalog_en from './locales/en/catalog.json';
import dashboard_en from './locales/en/dashboard.json';
import document_en from './locales/en/document.json';
import documentSetting_en from './locales/en/documentSetting.json';
import report_en from './locales/en/report.json';

import app_jp from './locales/jp/app.json';
import common_jp from './locales/jp/common.json';
import locale_jp from './locales/jp/locale.json';
import breadcrumb_jp from './locales/jp/breadcrumb.json';
import menu_jp from './locales/jp/menu.json';
import message_jp from './locales/jp/message.json';
import notification_jp from './locales/jp/notification.json';
import dataTransformation_jp from './locales/jp/dataTransformation.json';
import auth_jp from './locales/jp/auth.json';
import approval_jp from './locales/jp/approval.json';
import company_jp from './locales/jp/company.json';
import subscription_jp from './locales/jp/subscription.json';
import user_jp from './locales/jp/user.json';
import department_jp from './locales/jp/department.json';
import partner_jp from './locales/jp/partner.json';
import item_jp from './locales/jp/item.json';
import itemClassification_jp from './locales/jp/itemClassification.json';
import campaign_jp from './locales/jp/campaign.json';
import pricelist_jp from './locales/jp/pricelist.json';
import catalog_jp from './locales/jp/catalog.json';
import dashboard_jp from './locales/jp/dashboard.json';
import document_jp from './locales/jp/document.json';
import documentSetting_jp from './locales/jp/documentSetting.json';
import report_jp from './locales/jp/report.json';

const language = navigator.language;
const namespace = ["application", "common", "menu", "locale", "breadcrumb", "message", "notification"];
const languageDefault = "en-US";
const namespaceDefault = "application";
const languageMapping = { "en": "enUS", "ja": "jaJP", "en-US": "enUS", "ja-JP": "jaJP" };

const resources = {
    'en-US':
    {
        "application": app_en,
        "common": common_en,
        "locale": locale_en,
        "breadcrumb": breadcrumb_en,
        "menu": menu_en,
        "message": message_en,
        "notification": notification_en,
        "dataTransformation": dataTransformation_en,
        "auth": auth_en,
        "approval": approval_en,
        "company": company_en,
        "subscription": subscription_en,
        "user": user_en,
        "department": department_en,
        "partner": partner_en,
        "item": item_en,
        "itemClassification": itemClassification_en,
        "campaign": campaign_en,
        "pricelist": pricelist_en,
        "catalog": catalog_en,
        "dashboard": dashboard_en,
        "document": document_en,
        "documentSetting": documentSetting_en,
        "report": report_en
    },
    'ja':
    {
        "application": app_jp,
        "common": common_jp,
        "locale": locale_jp,
        "breadcrumb": breadcrumb_jp,
        "menu": menu_jp,
        "auth": auth_jp,
        "message": message_jp,
        "notification": notification_jp,
        "dataTransformation": dataTransformation_jp,
        "approval": approval_jp,
        "company": company_jp,
        "subscription": subscription_jp,
        "user": user_jp,
        "department": department_jp,
        "partner": partner_jp,
        "item": item_jp,
        "itemClassification": itemClassification_jp,
        "campaign": campaign_jp,
        "pricelist": pricelist_jp,
        "catalog": catalog_jp,
        "dashboard": dashboard_jp,
        "document": document_jp,
        "documentSetting": documentSetting_jp,
        "report": report_jp
    }
};

export { language, namespace, languageDefault, namespaceDefault, resources, languageMapping };