import { createSlice } from '@reduxjs/toolkit';

const Auth = {
  fields: {
    isLogged: false,
    keepLogged: false,
    user: null,
  },
  login: (state, action) => {
    state.auth.isLogged = true;
    state.auth.keepLogged = action?.payload?.keepLogged;
    state.auth.user = action?.payload?.user;
  },
  logout: (state, action) => {
    state.auth.isLogged = false;
    state.auth.user = null;    
  },
  keepLogged: (state, action) => {
    if (!state?.auth?.keepLogged) {
      Auth.logout(state, action);
    }
  }
};

const AuthState = {
  auth: Auth.fields
};

const initialState = ({ ...AuthState });

const AuthBehavior = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: Auth.login,
    logout: Auth.logout,
    keepLogged: Auth.keepLogged
  }
});

export const { login, logout, keepLogged } = AuthBehavior.actions;

export default AuthBehavior.reducer;