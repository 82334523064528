import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

import { persistConfig } from './persist';
import { AppReducers } from './reducers';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

const reducers = combineReducers({ ...AppReducers, router: routerReducer });

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [routerMiddleware]
  // middleware: (getDefaultMiddleware) =>
  // getDefaultMiddleware({
  // serializableCheck: false,
  // serializableCheck: {
  //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'setContext'],
  // }
  // }).concat(routerMiddleware)
});

export const history = createReduxHistory(store);
export const persistor = persistStore(store);
export default store;