export const saleTheme = {
    application: {
        main: '#4789d4',
        light: '#d6e5f5',
        dark: '#004c99',
        gradient: 'linear-gradient(180deg, #4789d4 0%, #99ccff 100%)',
        selected: '#ebf2fa',
        contrastText: '#fff'
    },
    header: {
        main: '#36393f',
        contrastText: '#fff'
    },
    logo: {
        light: '#d6e5f5',
        middle: '#99ccff',
        main: '#4789d4',
        dark: '#004c99',
        background: '#fff',
        constrastText: '#231815'
    },
    notification: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#4789d4'
    },
    search: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#4789d4'
    },
    grid: {
        header: '#ebf2fa',
        blankLine: '#ffffff',
        contrastLine: '#d8ebff',
        hoverLine: '#666',
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#4789d4'
    },
    new: {
        light: '#4789d4',
        main: '#4789d4',
        dark: '#4789d4',
        contrastText: '#fbfbfb'
    },
    edit: {
        light: '#4789d4',
        main: '#4789d4',
        dark: '#4789d4',
        contrastText: '#fbfbfb'
    },
    delete: {
        light: '#333333',
        main: '#333333',
        dark: '#333333',
        contrastText: '#fbfbfb'
    },
    background: {
        light: '#f5f5f5',
        main: '#f5f5f5',
        dark: '#f5f5f5',
        contrastText: '#000'
    },
    required: {
        light: '#ffe3e2',
        main: '#ffe3e2',
        dark: '#ffe3e2',
        contrastText: '#a40925'
    },
    primary: {
        light: '#d6e5f5',
        main: '#4789d4',
        dark: '#004c99',
        contrastText: '#fff'
    },
    secondary: {
        light: '#d6e5f5',
        main: '#4789d4',
        dark: '#004c99',
        contrastText: '#fff'
    },
    success: {
        light: '#baedea',
        main: '#289769',
        dark: '#baedea',
        contrastText: '#baedea'
    },
    error: {
        light: '#ffe3e2',
        main: '#a40925',
        dark: '#ffe3e2',
        contrastText: '#ffe3e2'
    },
    warning: {
        light: '#fff5ca',
        main: '#d98201',
        dark: '#fff5ca',
        contrastText: '#fff5ca'
    },
    info: {
        light: '#e2e5ec',
        main: '#9ba0ac',
        dark: '#e2e5ec',
        contrastText: '#e2e5ec'
    },
    generic: {
        main: "#333",
        contrastText: "#fff"
    },
    common: {
        black: '#333',
        white: '#fff',
        red: '#a40925',
        gray: '#9ba0ac',
        orange: '#ff6c2e'
    },
    document: {
        light: '#d6e5f5',
        main: '#4789d4',
        dark: '#004c99'
    },
    dashboard: {
        amber: {
            main: '#d98201',
            contrastText: '#fff5ca'
        },
        blue: {
            main: '#1c32a8',
            contrastText: '#dae4ff'
        },
        blueGrey: {
            main: '#fff',
            contrastText: '#000'
        },
        green: {
            main: '#289769',
            contrastText: '#cbf4cc'
        },
        grey: {
            main: '#fff',
            contrastText: '#000'
        },
        purple: {
            main: '#fff',
            contrastText: '#000'
        },
        red: {
            main: '#a40925',
            contrastText: '#ffe3e2'
        },
        yellow: {
            main: '#fff',
            contrastText: '#000'
        },
    }
}