import { lazy } from 'react';
import { Navigate } from "react-router-dom";
import TopLoader from 'components/loader/Loader';

const AuthMain = TopLoader(lazy(() => import('auth/layout')));
const SignIn = TopLoader(lazy(() => import('auth/pages/SignIn')));
const SignUp = TopLoader(lazy(() => import('auth/pages/SignUp')));
const PasswordForgot = TopLoader(lazy(() => import('auth/pages/PasswordForgot')));
const PasswordRecovery = TopLoader(lazy(() => import('auth/pages/PasswordRecovery')));
const FirstAccess = TopLoader(lazy(() => import('auth/pages/first-access')));

const AuthRoutes = {
    path: '/',
    element: <AuthMain />,
    children: [
        {
            path: '*',
            element: <Navigate to="/sign-in" />
        },
        {
            path: 'sign-in',
            element: <SignIn />
        },
        {
            path: 'sign-up',
            element: <SignUp />
        },
        {
            path: 'password-forgot',
            element: <PasswordForgot />
        },
        {
            path: 'password-recovery',
            element: <PasswordRecovery />
        },
        {
            path: 'first-access',
            element: <FirstAccess />
        }
    ]
};

export default AuthRoutes;