import { createSlice } from '@reduxjs/toolkit';
import { BrowserUI, ColorMode, MsgType, SystemMode } from 'common/uiWrapper';

const Screen = {
  fields: {
    systemMode: SystemMode.DEFAULT,
    colorMode: ColorMode.LIGHT,
    width: BrowserUI.screenWidth(),
    height: BrowserUI.screenHeight(),
    isMobile: BrowserUI.isMobile(),
    isTablet: BrowserUI.isTablet(),
    isReadOnly: false
  },
  setSystemMode: (state, action) => {
    state.screen.systemMode = action.payload;
  },
  toggleColorMode: (state, action) => {
    state.screen.colorMode = (state.screen.colorMode === ColorMode.LIGHT ? ColorMode.DARK : ColorMode.LIGHT);
  },
  notifyReadOnly: (state, action) => {
    state.screen.isReadOnly = action.payload;
  },
  notifyWindowSizeChange: (state, action) => {
    state.screen.width = BrowserUI.screenWidth();
    state.screen.height = BrowserUI.screenHeight();
    state.screen.isMobile = BrowserUI.isMobile();
    state.screen.isTablet = BrowserUI.isTablet();
    state.menu.width = BrowserUI.menuWidth();
  }
};

const Profile = {
  fields: {
    visible: false,
    pictureUrl: null,
    msg: []
  },
  showOptions: (state, action) => {
    state.profile.visible = action.payload;
  },
  loadPicture: (state, action) => {
    state.profile.pictureUrl = action.payload;
  },
  loadNotifications: (state, action) => {
    state.profile.msg = action.payload;
  },
  clearNotifications: (state, action) => {
    state.profile.msg = [];
  }
}

const Menu = {
  fields: {
    category: "",
    item: "",
    visible: false,
    width: BrowserUI.menuWidth()
  },
  setCategory: (state, action) => {
    state.menu.category = action.payload;
  },
  setItem: (state, action) => {
    state.menu.item = action.payload;
  },
  showMenu: (state, action) => {
    state.menu.visible = action.payload;
  }
};

const Feedback = {
  fields: {
    hasMessage: false,
    messageType: MsgType.NONE,
    message: "",
    notification: null
  },
  showNotification: (state, action) => {
    state.feedback.notification = action?.payload?.notification;
  },
  clearNotification: (state, action) => {
    state.feedback.notification = null;
  },
  showMsg: (state, action) => {
    state.feedback.hasMessage = true;
    state.feedback.messageType = action?.payload?.type;
    state.feedback.message = action?.payload?.message?.toString();
  },
  clearMsg: (state, action) => {
    state.feedback.hasMessage = false;
    state.feedback.messageType = MsgType.NONE;
    state.feedback.message = "";
  }
};

const UIState = {
  screen: Screen.fields,
  profile: Profile.fields,
  menu: Menu.fields,
  feedback: Feedback.fields
};

const initialState = ({ ...UIState });

const UIBehavior = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSystemMode: Screen.setSystemMode,
    toggleColorMode: Screen.toggleColorMode,
    notifyReadOnly: Screen.notifyReadOnly,
    notifyWindowSizeChange: Screen.notifyWindowSizeChange,
    showProfileOptions: Profile.showOptions,
    loadProfilePicture: Profile.loadPicture,
    loadProfileNotifications: Profile.loadNotifications,
    clearProfileNotifications: Profile.clearNotifications,
    showSideMenu: Menu.showMenu,
    setSideMenuCategory: Menu.setCategory,
    setSideMenuItem: Menu.setItem,
    showMsg: Feedback.showMsg,
    clearMsg: Feedback.clearMsg,
    showNotification: Feedback.showNotification,
    clearNotification: Feedback.clearNotification
  }
});

export const { setSystemMode, toggleColorMode, notifyReadOnly, notifyWindowSizeChange, showProfileOptions, loadProfilePicture, loadProfileNotifications, clearProfileNotifications, showSideMenu, setSideMenuCategory, setSideMenuItem, showMsg, clearMsg, showNotification, clearNotification } = UIBehavior.actions;

export default UIBehavior.reducer;