import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as locales from '@mui/material/locale';

import { ColorMode, SystemMode } from 'common/uiWrapper';
import { lightTheme } from './lightTheme';
import { darkTheme } from './darkTheme';
import { saleTheme } from './saleTheme';
import { buyTheme } from './buyTheme';

const AppTheme = ({ locale, children }) => {
    const { screen } = useSelector((state) => state.ui);

    const colorMode = (colorMode, systemMode) => {
        switch (colorMode) {
            case ColorMode.LIGHT:
                switch (systemMode) {
                    case SystemMode.SALE:
                        return saleTheme;
                    case SystemMode.BUY:
                        return buyTheme;
                    default:
                        return lightTheme;
                }
            case ColorMode.DARK:
                return darkTheme;
            default:
                return lightTheme;
        }
    }

    const theme = createTheme({
        palette: {
            mode: screen?.colorMode,
            ...colorMode(screen?.colorMode, screen?.systemMode)
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    '*': {
                        zoom: '100%',
                    },
                    html: {
                        height: '100%',
                    },
                    body: {
                        height: '100%',
                        margin: "0 0 0 0",
                        '*::-webkit-scrollbar': {
                            width: '0.3em',
                            height: '0.3em'
                        },
                        '*::-webkit-scrollbar-track': {
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: '#e7F3ee',
                        },
                    },
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            },
        }
    },
        locales[locale]
    );

    const { breakpoints } = theme;

    theme.typography.h1 = {
        ...theme.typography.h1,
        fontWeight: 'bold',
        [breakpoints.up('xs')]: {
            fontSize: '5rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '5.4rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '5.8rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '6rem',
        }
    };

    theme.typography.h2 = {
        ...theme.typography.h2,
        fontWeight: 'bold',
        [breakpoints.up('xs')]: {
            fontSize: '3rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '3.25rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '3.5rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '3.75rem',
        }
    };

    theme.typography.h3 = {
        ...theme.typography.h3,
        fontWeight: 'bold',
        [breakpoints.up('xs')]: {
            fontSize: '2.2rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '2.4rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '2.8rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '3rem',
        }
    };

    theme.typography.h4 = {
        ...theme.typography.h4,
        fontWeight: 'bold',
        [breakpoints.up('xs')]: {
            fontSize: '1.75rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '1.95rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '2.05rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '2.125rem',
        }
    };

    theme.typography.h5 = {
        ...theme.typography.h5,
        fontWeight: 'bold',
        [breakpoints.up('xs')]: {
            fontSize: '1rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '1.1rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '1.2rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        }
    };

    theme.typography.h6 = {
        ...theme.typography.h6,
        fontWeight: 'bold',
        [breakpoints.up('xs')]: {
            fontSize: '0.9rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '1.1rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1.25rem',
        }
    };

    theme.typography.subtitle1 = {
        ...theme.typography.subtitle1,
        [breakpoints.up('xs')]: {
            fontSize: '0.6rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1rem',
        }
    };

    theme.typography.subtitle2 = {
        ...theme.typography.subtitle2,
        [breakpoints.up('xs')]: {
            fontSize: '0.55rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.65rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.75rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.875rem',
        }
    };

    theme.typography.body1 = {
        ...theme.typography.body1,
        [breakpoints.up('xs')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.9rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '1rem',
        }
    };

    theme.typography.body2 = {
        ...theme.typography.body2,
        [breakpoints.up('xs')]: {
            fontSize: '0.75rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.825rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.875rem',
        }
    };

    theme.typography.button = {
        ...theme.typography.button,
        [breakpoints.up('xs')]: {
            fontSize: '0.75rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.8rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.825rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.875rem',
        }
    };

    theme.typography.caption = {
        ...theme.typography.caption,
        [breakpoints.up('xs')]: {
            fontSize: '0.6rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.65rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.75rem',
        }
    };

    theme.typography.overline = {
        ...theme.typography.overline,
        [breakpoints.up('xs')]: {
            fontSize: '0.6rem',
        },
        [breakpoints.up('sm')]: {
            fontSize: '0.65rem',
        },
        [breakpoints.up('md')]: {
            fontSize: '0.7rem',
        },
        [breakpoints.up('lg')]: {
            fontSize: '0.75rem',
        }
    };

    theme.typography.documentBody = {
        fontSize: '1.2em',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        letterSpacing: "0.00938em",
        lineHeight: 1.5
    };

    theme.typography.documentTitle = {
        fontSize: '2.2em',
        fontWeight: "bold",
        letterSpacing: "0.00735em",
        lineHeight: 1.235,
        textTransform: 'uppercase'
    };

    theme.typography.documentSubtitle = {
        fontSize: '1.5em',
        fontWeight: "bold",
        letterSpacing: "0em",
        lineHeight: 1.334
    };

    theme.typography.documentCode = {
        fontSize: '1em',
        fontWeight: 100,
        letterSpacing: "0.08333em",
        lineHeight: 0.833
    };

    theme.typography.documentItem = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        fontSize: '1em',
        fontWeight: 400,
        letterSpacing: "0.08333em",
        lineHeight: 1.33
    };

    theme.typography.documentItemHeader = {
        fontSize: '1em',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        letterSpacing: "0.08333em",
        lineHeight: 2.66,
        textTransform: 'uppercase'
    };

    theme.typography.documentItemMemo = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        fontSize: '.8em',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 200,
        letterSpacing: "0.08333em",
        lineHeight: 1.0,
    };

    theme.typography.documentCaption = {
        fontSize: '1em',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        letterSpacing: "0.03333em",
        lineHeight: 1.66
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

AppTheme.propTypes = {
    locales: PropTypes.string,
    children: PropTypes.node
};

export default AppTheme;