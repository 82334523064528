import { lazy } from 'react';
import { Navigate } from "react-router-dom";
import Loader from 'components/loader/Loader';

const AdminMain = Loader(lazy(() => import('admin/layout')));
const DashboardMaster = Loader(lazy(() => import('admin/pages/dashboard/master')));
const DashboardMain = Loader(lazy(() => import('admin/pages/dashboard/main-workspace')));
const DashboardPlan = Loader(lazy(() => import('admin/pages/dashboard/plan-workspace')));

const Profile = Loader(lazy(() => import('admin/pages/profile')));

const NotificationMaster = Loader(lazy(() => import('admin/notification/master')));
const Notification = Loader(lazy(() => import('admin/notification')));

const DataTransformationMaster = Loader(lazy(() => import('admin/pages/dataTransformation/master')));
const DataTransformationListing = Loader(lazy(() => import('admin/pages/dataTransformation')));

const Company = Loader(lazy(() => import('admin/pages/company')));

const SubscriptionMaster = Loader(lazy(() => import('admin/pages/subscription/master')));
const Subscription = Loader(lazy(() => import('admin/pages/subscription')));

const UserMaster = Loader(lazy(() => import('admin/pages/user/master')));
const UserListing = Loader(lazy(() => import('admin/pages/user')));
const UserForm = Loader(lazy(() => import('admin/pages/user/form')));

const DepartmentMaster = Loader(lazy(() => import('admin/pages/department/master')));
const DepartmentListing = Loader(lazy(() => import('admin/pages/department')));
const DepartmentForm = Loader(lazy(() => import('admin/pages/department/form')));

const PartnerMaster = Loader(lazy(() => import('admin/pages/partner/master')));
const PartnerListing = Loader(lazy(() => import('admin/pages/partner')));
const PartnerForm = Loader(lazy(() => import('admin/pages/partner/form')));

const ItemMaster = Loader(lazy(() => import('admin/pages/item/master')));
const ItemListing = Loader(lazy(() => import('admin/pages/item')));
const ItemForm = Loader(lazy(() => import('admin/pages/item/form')));

const ItemClassificationMaster = Loader(lazy(() => import('admin/pages/itemClassification/master')));
const ItemClassificationListing = Loader(lazy(() => import('admin/pages/itemClassification')));
const ItemClassificationForm = Loader(lazy(() => import('admin/pages/itemClassification/form')));

const CampaignMaster = Loader(lazy(() => import('admin/pages/campaign/master')));
const CampaignListing = Loader(lazy(() => import('admin/pages/campaign')));
const CampaignForm = Loader(lazy(() => import('admin/pages/campaign/form')));

const PricelistMaster = Loader(lazy(() => import('admin/pages/pricelist/master')));
const PricelistListing = Loader(lazy(() => import('admin/pages/pricelist')));
const PricelistForm = Loader(lazy(() => import('admin/pages/pricelist/form')));

const CatalogMaster = Loader(lazy(() => import('admin/pages/catalog/master')));
const CatalogListing = Loader(lazy(() => import('admin/pages/catalog')));
const CatalogForm = Loader(lazy(() => import('admin/pages/catalog/form')));

const DocumentSettingMaster = Loader(lazy(() => import('admin/pages/document/settings/master')));
const DocumentSettingListing = Loader(lazy(() => import('admin/pages/document/settings/listing')));
const DocumentSettingForm = Loader(lazy(() => import('admin/pages/document/settings/form')));

const DocumentImportMaster = Loader(lazy(() => import('admin/pages/document/import/master')));
const DocumentImportManager = Loader(lazy(() => import('admin/pages/document/import/manager')));
const DocumentImport = Loader(lazy(() => import('admin/pages/document/import')));

const BuyQuotationMaster = Loader(lazy(() => import('admin/pages/document/buySlip/quotation/master')));
const BuyQuotationListing = Loader(lazy(() => import('admin/pages/document/buySlip/quotation/listing')));
const BuyQuotationEditor = Loader(lazy(() => import('admin/pages/document/buySlip/quotation/editor')));

const BuyOrderMaster = Loader(lazy(() => import('admin/pages/document/buySlip/order/master')));
const BuyOrderListing = Loader(lazy(() => import('admin/pages/document/buySlip/order/listing')));
const BuyOrderEditor = Loader(lazy(() => import('admin/pages/document/buySlip/order/editor')));

const BuyCouponMaster = Loader(lazy(() => import('admin/pages/document/buySlip/coupon/master')));
const BuyCouponListing = Loader(lazy(() => import('admin/pages/document/buySlip/coupon/listing')));
const BuyCouponEditor = Loader(lazy(() => import('admin/pages/document/buySlip/coupon/editor')));

const BuyInvoiceMaster = Loader(lazy(() => import('admin/pages/document/buySlip/invoice/master')));
const BuyInvoiceListing = Loader(lazy(() => import('admin/pages/document/buySlip/invoice/listing')));
const BuyInvoiceEditor = Loader(lazy(() => import('admin/pages/document/buySlip/invoice/editor')));

const BuyReceiptMaster = Loader(lazy(() => import('admin/pages/document/buySlip/receipt/master')));
const BuyReceiptListing = Loader(lazy(() => import('admin/pages/document/buySlip/receipt/listing')));
const BuyReceiptEditor = Loader(lazy(() => import('admin/pages/document/buySlip/receipt/editor')));

const BuyDocumentMaster = Loader(lazy(() => import('admin/pages/document/buySlip/master')));
const BuyDocumentListing = Loader(lazy(() => import('admin/pages/document/buySlip')));
const BuyDocumentRevision = Loader(lazy(() => import('admin/pages/document/buySlip/revision')));
const BuyDocumentRevisionConfirm = Loader(lazy(() => import('admin/pages/document/buySlip/revision-confirmation')));

const SaleQuotationMaster = Loader(lazy(() => import('admin/pages/document/saleSlip/quotation/master')));
const SaleQuotationListing = Loader(lazy(() => import('admin/pages/document/saleSlip/quotation/listing')));
const SaleQuotationEditor = Loader(lazy(() => import('admin/pages/document/saleSlip/quotation/editor')));

const SaleOrderMaster = Loader(lazy(() => import('admin/pages/document/saleSlip/order/master')));
const SaleOrderListing = Loader(lazy(() => import('admin/pages/document/saleSlip/order/listing')));
const SaleOrderEditor = Loader(lazy(() => import('admin/pages/document/saleSlip/order/editor')));

const SaleCouponMaster = Loader(lazy(() => import('admin/pages/document/saleSlip/coupon/master')));
const SaleCouponListing = Loader(lazy(() => import('admin/pages/document/saleSlip/coupon/listing')));
const SaleCouponEditor = Loader(lazy(() => import('admin/pages/document/saleSlip/coupon/editor')));

const SaleInvoiceMaster = Loader(lazy(() => import('admin/pages/document/saleSlip/invoice/master')));
const SaleInvoiceListing = Loader(lazy(() => import('admin/pages/document/saleSlip/invoice/listing')));
const SaleInvoiceEditor = Loader(lazy(() => import('admin/pages/document/saleSlip/invoice/editor')));

const SaleReceiptMaster = Loader(lazy(() => import('admin/pages/document/saleSlip/receipt/master')));
const SaleReceiptListing = Loader(lazy(() => import('admin/pages/document/saleSlip/receipt/listing')));
const SaleReceiptEditor = Loader(lazy(() => import('admin/pages/document/saleSlip/receipt/editor')));

const SaleDocumentMaster = Loader(lazy(() => import('admin/pages/document/saleSlip/master')));
const SaleDocumentListing = Loader(lazy(() => import('admin/pages/document/saleSlip')));
const SaleDocumentRevision = Loader(lazy(() => import('admin/pages/document/saleSlip/revision')));
const SaleDocumentRevisionConfirm = Loader(lazy(() => import('admin/pages/document/saleSlip/revision-confirmation')));

const ReportSaleMaster = Loader(lazy(() => import('admin/pages/report/saleSlip/master')));
const ReportSaleConsolidated = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-consolidated-report')));
const ReportSalePerPartner = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-per-partner-report')));
const ReportSalePerDepartment = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-per-department-report')));
const ReportSalePerUser = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-per-user-report')));
const ReportSalePerItem = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-per-item-report')));
const ReportSalePerTag = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-per-tag-report')));
const ReportSalePerMajorClassification = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-per-major-classification-report')));
const ReportSalePerMinorClassification = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-per-minor-classification-report')));
const ReportSalePaymentManagement = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-payment-management-report')));
const ReportSaleGrossProfit = Loader(lazy(() => import('admin/pages/report/saleSlip/sale-gross-profit-report')));

const ReportBuyMaster = Loader(lazy(() => import('admin/pages/report/buySlip/master')));
const ReportBuyConsolidated = Loader(lazy(() => import('admin/pages/report/buySlip/buy-consolidated-report')));
const ReportBuyPerPartner = Loader(lazy(() => import('admin/pages/report/buySlip/buy-per-partner-report')));
const ReportBuyPerDepartment = Loader(lazy(() => import('admin/pages/report/buySlip/buy-per-department-report')));
const ReportBuyPerUser = Loader(lazy(() => import('admin/pages/report/buySlip/buy-per-user-report')));
const ReportBuyPerItem = Loader(lazy(() => import('admin/pages/report/buySlip/buy-per-item-report')));
const ReportBuyPerTag = Loader(lazy(() => import('admin/pages/report/buySlip/buy-per-tag-report')));
const ReportBuyPerMajorClassification = Loader(lazy(() => import('admin/pages/report/buySlip/buy-per-major-classification-report')));
const ReportBuyPerMinorClassification = Loader(lazy(() => import('admin/pages/report/buySlip/buy-per-minor-classification-report')));
const ReportBuyPaymentManagement = Loader(lazy(() => import('admin/pages/report/buySlip/buy-payment-management-report')));

const AdminRoutes = {
    path: '/admin',
    element: <AdminMain />,
    children: [
        {
            path: '*',
            element: <Navigate to="/admin/dashboard" />
        },
        {
            path: 'dashboard',
            element: <DashboardMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="main" />
                },
                {
                    path: 'main',
                    element: <DashboardMain />
                },
                {
                    path: 'plan',
                    element: <DashboardPlan />
                }
            ]
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'notification',
            element: <NotificationMaster />,
            children: [
                {
                    path: '',
                    element: <Notification />
                }
            ]
        },
        {
            path: 'data-transformation',
            element: <DataTransformationMaster />,
            children: [
                {
                    path: '',
                    element: <DataTransformationListing />
                }
            ]
        },
        {
            path: 'company',
            element: <Company />
        },
        {
            path: 'subscription',
            element: <SubscriptionMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="select" />
                },
                {
                    path: 'select',
                    element: <Subscription />
                }
            ]
        },
        {
            path: 'user',
            element: <UserMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <UserListing />
                },
                {
                    path: 'form/:id?',
                    element: <UserForm />
                }
            ]
        },
        {
            path: 'department',
            element: <DepartmentMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <DepartmentListing />
                },
                {
                    path: 'form',
                    element: <DepartmentForm />
                }
            ]
        },
        {
            path: 'partner',
            element: <PartnerMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <PartnerListing />,
                },
                {
                    path: 'form',
                    element: <PartnerForm />,
                },
            ]
        },
        {
            path: 'item',
            element: <ItemMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <ItemListing />
                },
                {
                    path: 'form',
                    element: <ItemForm />
                }
            ]
        },
        {
            path: 'item-classification',
            element: <ItemClassificationMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <ItemClassificationListing />
                },
                {
                    path: 'form',
                    element: <ItemClassificationForm />
                }
            ]
        },
        {
            path: 'campaign',
            element: <CampaignMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <CampaignListing />
                },
                {
                    path: 'form',
                    element: <CampaignForm />
                }
            ]
        },
        {
            path: 'pricelist',
            element: <PricelistMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <PricelistListing />
                },
                {
                    path: 'form',
                    element: <PricelistForm />
                }
            ]
        },
        {
            path: 'catalog',
            element: <CatalogMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <CatalogListing />
                },
                {
                    path: 'form',
                    element: <CatalogForm />
                }
            ]
        },
        {
            path: 'document',
            element: <DocumentSettingMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="list" />
                },
                {
                    path: 'list',
                    element: <DocumentSettingListing />
                },
                {
                    path: 'form',
                    element: <DocumentSettingForm />
                }
            ]
        },
        {
            path: 'document',
            element: <DocumentImportMaster />,
            children: [
                {
                    path: '*',
                    element: <Navigate to="dashboard" />
                },
                {
                    path: 'import',
                    element: <DocumentImport />
                },
                {
                    path: 'import/manager',
                    element: <DocumentImportManager />
                },
            ]
        },
        {
            path: 'buy-slip',
            children: [
                {
                    path: '*',
                    element: <Navigate to="dashboard" />
                },
                {
                    path: 'quotation',
                    element: <BuyQuotationMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <BuyQuotationListing />
                        },
                        {
                            path: 'editor',
                            element: <BuyQuotationEditor />
                        },
                    ]
                },
                {
                    path: 'order',
                    element: <BuyOrderMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <BuyOrderListing />
                        },
                        {
                            path: 'editor',
                            element: <BuyOrderEditor />
                        }
                    ]
                },
                {
                    path: 'coupon',
                    element: <BuyCouponMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <BuyCouponListing />
                        },
                        {
                            path: 'editor',
                            element: <BuyCouponEditor />
                        },
                    ]
                },
                {
                    path: 'invoice',
                    element: <BuyInvoiceMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <BuyInvoiceListing />
                        },
                        {
                            path: 'editor',
                            element: <BuyInvoiceEditor />
                        },
                    ]
                },
                {
                    path: 'receipt',
                    element: <BuyReceiptMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <BuyReceiptListing />
                        },
                        {
                            path: 'editor',
                            element: <BuyReceiptEditor />
                        },
                    ]
                },
                {
                    path: 'document',
                    element: <BuyDocumentMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list',
                            element: <BuyDocumentListing />
                        },
                        {
                            path: 'revision',
                            element: <BuyDocumentRevision />
                        },
                        {
                            path: 'revision/confirm/:documentId',
                            element: <BuyDocumentRevisionConfirm />
                        }
                    ]
                }
            ]
        },
        {
            path: 'sale-slip',
            children: [
                {
                    path: '*',
                    element: <Navigate to="dashboard" />
                },
                {
                    path: 'quotation',
                    element: <SaleQuotationMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <SaleQuotationListing />
                        },
                        {
                            path: 'editor',
                            element: <SaleQuotationEditor />
                        }
                    ]
                },
                {
                    path: 'order',
                    element: <SaleOrderMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <SaleOrderListing />
                        },
                        {
                            path: 'editor',
                            element: <SaleOrderEditor />
                        }
                    ]
                },
                {
                    path: 'coupon',
                    element: <SaleCouponMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <SaleCouponListing />
                        },
                        {
                            path: 'editor',
                            element: <SaleCouponEditor />
                        }
                    ]
                },
                {
                    path: 'invoice',
                    element: <SaleInvoiceMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <SaleInvoiceListing />
                        },
                        {
                            path: 'editor',
                            element: <SaleInvoiceEditor />
                        }
                    ]
                },
                {
                    path: 'receipt',
                    element: <SaleReceiptMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list/:code?',
                            element: <SaleReceiptListing />
                        },
                        {
                            path: 'editor',
                            element: <SaleReceiptEditor />
                        }
                    ]
                },
                {
                    path: 'document',
                    element: <SaleDocumentMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="list" />
                        },
                        {
                            path: 'list',
                            element: <SaleDocumentListing />
                        },
                        {
                            path: 'revision',
                            element: <SaleDocumentRevision />
                        },
                        {
                            path: 'revision/confirm/:documentId',
                            element: <SaleDocumentRevisionConfirm />
                        }
                    ]
                }
            ]
        },
        {
            path: 'report',
            children: [
                {
                    path: '*',
                    element: <Navigate to="sale-slip" />
                },
                {
                    path: 'sale-slip',
                    element: <ReportSaleMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="per-customer" />
                        },
                        {
                            path: 'sale-consolidated/:startDate?/:endDate?/:user?',
                            element: <ReportSaleConsolidated />
                        },
                        {
                            path: 'per-customer',
                            element: <ReportSalePerPartner />
                        },
                        {
                            path: 'per-department',
                            element: <ReportSalePerDepartment />
                        },
                        {
                            path: 'per-user/:startDate?/:endDate?/:user?',
                            element: <ReportSalePerUser />
                        },
                        {
                            path: 'per-item',
                            element: <ReportSalePerItem />
                        },
                        {
                            path: 'per-tag',
                            element: <ReportSalePerTag />
                        },
                        {
                            path: 'per-major-classification',
                            element: <ReportSalePerMajorClassification />
                        },
                        {
                            path: 'per-minor-classification',
                            element: <ReportSalePerMinorClassification />
                        },
                        {
                            path: 'payment-management',
                            element: <ReportSalePaymentManagement />
                        },
                        {
                            path: 'gross-profit/:startDate?/:endDate?/:user?',
                            element: <ReportSaleGrossProfit />
                        },
                    ]
                },
                {
                    path: 'buy-slip',
                    element: <ReportBuyMaster />,
                    children: [
                        {
                            path: '*',
                            element: <Navigate to="per-supplier" />
                        },
                        {
                            path: 'buy-consolidated/:startDate?/:endDate?/:user?',
                            element: <ReportBuyConsolidated />
                        },
                        {
                            path: 'per-supplier',
                            element: <ReportBuyPerPartner />
                        },
                        {
                            path: 'per-department',
                            element: <ReportBuyPerDepartment />
                        },
                        {
                            path: 'per-user',
                            element: <ReportBuyPerUser />
                        },
                        {
                            path: 'per-item',
                            element: <ReportBuyPerItem />
                        },
                        {
                            path: 'per-tag',
                            element: <ReportBuyPerTag />
                        },
                        {
                            path: 'per-major-classification',
                            element: <ReportBuyPerMajorClassification />
                        },
                        {
                            path: 'per-minor-classification',
                            element: <ReportBuyPerMinorClassification />
                        },
                        {
                            path: 'payment-management',
                            element: <ReportBuyPaymentManagement />
                        },
                    ]
                }
            ]
        }
    ]
};

export default AdminRoutes;