export const buyTheme = {
    application: {
        main: '#ff6c2e',
        light: '#ffd3c0',
        dark: '#bc6f18',
        gradient: 'linear-gradient(180deg, #ff6c2e 0%, #ffb266 100%)',
        selected: '#fffaf3',
        contrastText: '#fff'
    },
    header: {
        main: '#36393f',
        contrastText: '#fff'
    },
    logo: {
        light: '#ffd3c0',
        middle: '#ffb266',
        main: '#ff6c2e',
        dark: '#bc6f18',
        background: '#fff',
        constrastText: '#231815'
    },
    notification: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#ff6c2e'
    },
    search: {
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#ff6c2e'
    },
    grid: {
        header: '#fff8f5',
        blankLine: '#ffffff',
        contrastLine: '#fff8f5',
        hoverLine: '#666',
        light: '#fbfbfb',
        main: '#fbfbfb',
        dark: '#fbfbfb',
        contrastText: '#ff6c2e'
    },
    new: {
        light: '#ff6c2e',
        main: '#ff6c2e',
        dark: '#ff6c2e',
        contrastText: '#fbfbfb'
    },
    edit: {
        light: '#ff6c2e',
        main: '#ff6c2e',
        dark: '#ff6c2e',
        contrastText: '#fbfbfb'
    },
    delete: {
        light: '#333333',
        main: '#333333',
        dark: '#333333',
        contrastText: '#fbfbfb'
    },
    background: {
        light: '#f5f5f5',
        main: '#f5f5f5',
        dark: '#f5f5f5',
        contrastText: '#000'
    },
    required: {
        light: '#ffe3e2',
        main: '#ffe3e2',
        dark: '#ffe3e2',
        contrastText: '#a40925'
    },
    primary: {
        light: '#ffdfb9',
        main: '#ff6c2e',
        dark: '#bc6f18',
        contrastText: '#fff'
    },
    secondary: {
        light: '#ffdfb9',
        main: '#ff6c2e',
        dark: '#bc6f18',
        contrastText: '#fff'
    },
    success: {
        light: '#baedea',
        main: '#289769',
        dark: '#baedea',
        contrastText: '#baedea'
    },
    error: {
        light: '#ffe3e2',
        main: '#a40925',
        dark: '#ffe3e2',
        contrastText: '#ffe3e2'
    },
    warning: {
        light: '#fff5ca',
        main: '#d98201',
        dark: '#fff5ca',
        contrastText: '#fff5ca'
    },
    info: {
        light: '#e2e5ec',
        main: '#9ba0ac',
        dark: '#e2e5ec',
        contrastText: '#e2e5ec'
    },
    generic: {
        main: "#333",
        contrastText: "#fff"
    },
    common: {
        black: '#333',
        white: '#fff',
        red: '#a40925',
        gray: '#9ba0ac',
        orange: '#ff6c2e'
    },
    document: {
        light: '#ffdfb9',
        main: '#ff6c2e',
        dark: '#bc6f18'
    },
    dashboard: {
        amber: {
            main: '#d98201',
            contrastText: '#fff5ca'
        },
        blue: {
            main: '#1c32a8',
            contrastText: '#dae4ff'
        },
        blueGrey: {
            main: '#fff',
            contrastText: '#000'
        },
        green: {
            main: '#289769',
            contrastText: '#cbf4cc'
        },
        grey: {
            main: '#fff',
            contrastText: '#000'
        },
        purple: {
            main: '#fff',
            contrastText: '#000'
        },
        red: {
            main: '#a40925',
            contrastText: '#ffe3e2'
        },
        yellow: {
            main: '#fff',
            contrastText: '#000'
        },
    }
}