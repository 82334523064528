import { Navigate } from "react-router-dom";

const DefaultRoutes = {
    root: {
        path: '/',
        element: <Navigate to="/sign-in" />,
    },
    admin: {
        path: '/admin',
        element: <Navigate to="/admin/dashboard" />
    }
};

export default DefaultRoutes;