import { lazy } from 'react';
import { Navigate } from "react-router-dom";
import Loader from 'components/loader/Loader';

const AppMain = Loader(lazy(() => import('app/layout')));

const AppCleanUp = Loader(lazy(() => import('app/pages/cleanup')));

const AppDashboard = Loader(lazy(() => import('app/pages/dashboard')));

const AppDocumentApprove = Loader(lazy(() => import('app/pages/document/approve')));
const AppDocumentReject = Loader(lazy(() => import('app/pages/document/reject')));
const AppDocumentViewer = Loader(lazy(() => import('app/pages/document/viewer')));

const CatalogCheckoutMaster = Loader(lazy(() => import('app/pages/catalog/checkout/master')));
const CatalogCheckout = Loader(lazy(() => import('app/pages/catalog/checkout')));
const CatalogCheckoutMessage = Loader(lazy(() => import('app/pages/catalog/checkout/message')));

const AppRoutes = {
    path: '/',
    element: <AppMain />,
    children: [
        {
            path: '*',
            element: <Navigate to="/sign-in" />
        },
        {
            path: 'dashboard',
            element: <AppDashboard />
        },
        {
            path: 'clean-up',
            element: <AppCleanUp />
        },
        {
            path: 'document/approve',
            element: <AppDocumentApprove />
        },
        {
            path: 'document/reject',
            element: <AppDocumentReject />
        },
        {
            path: 'document/external',
            element: <AppDocumentViewer />
        },
        {
            path: 'catalog',
            element: <CatalogCheckoutMaster />,
            children: [
                {
                    path: 'checkout/:id?',
                    element: <CatalogCheckout />
                },
                {
                    path: 'checkout/message',
                    element: <CatalogCheckoutMessage />
                }

            ]
        }
    ]
};

export default AppRoutes;